import React from 'react';
import { useChallengesList } from './context';

export function withChallengesList<IProps = any>(Component: any) {
  return (props: IProps) => {
    const list = useChallengesList();

    return <Component {...(props as IProps)} {...list} />;
  };
}
