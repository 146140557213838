import React from 'react';
import { useMemberPaidPlans } from './context';

export const withPaidPlans = <Props = any,>(
  Component: React.ComponentType<Props>,
) => {
  return (props: Props) => {
    const value = useMemberPaidPlans();
    return <Component {...props} {...value} />;
  };
};
