import { Category } from '@wix/ambassador-achievements-categories-v3-category/types';
import programListSettings, { ALL_PROGRAM_CATEGORY } from './settingsParams';
import { getShortId } from '../../services/getOrderedItems';

export function getProgramOrderKey(chosenCategory: string) {
  return chosenCategory === ALL_PROGRAM_CATEGORY
    ? 'order'
    : `c${chosenCategory}`;
}

export function getProgramsOrder(opts: {
  settings: {
    get(key: (typeof programListSettings)['challengesOrdering']): any;
  };
  chosenCategory: string;
  categories: Category[];
}): { id: string }[] {
  const orderSettings = opts.settings.get(
    programListSettings.challengesOrdering,
  );
  if (orderSettings?.[getProgramOrderKey(opts.chosenCategory)]?.length) {
    return orderSettings[getProgramOrderKey(opts.chosenCategory)];
  }
  if (opts.chosenCategory !== ALL_PROGRAM_CATEGORY) {
    const category = (opts.categories || []).find(
      (c) => c.id === opts.chosenCategory,
    );
    return (category?.assignedTo || []).map((id) => ({
      id: getShortId(id),
    }));
  }

  return orderSettings?.order || [];
}
