import { ICategoriesContext } from './interfaces';
import { useControllerData } from '../../main/controllerContext';

export const useCategories = (): ICategoriesContext => {
  const props = useControllerData();

  return {
    categoriesData: props.categoriesData,
    categoryToPreview: props.categoryToPreview,
  };
};
