import { useCallback } from 'react';
import { useControllerData } from '../../main/controllerContext';
import { IChallengesListContext } from './interfaces';

export const useChallengesList = (): IChallengesListContext => {
  const props = useControllerData();

  const getProgramLink = useCallback(
    (program: { id?: string }) => {
      return program?.id ? props.linksToProgram[program.id] : '/';
    },
    [props.linksToProgram],
  );

  return {
    challengesListData: props.challengesListData,
    linksToProgram: {},
    getProgramLink,
    goToProgramPage: props.goToProgramPage,
  };
};
