import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { getProgramsOrder } from '../getProgramsOrderSettingsKey';
import { Category } from '@wix/ambassador-achievements-categories-v3-category/types';
import { getShortId } from '../../../services/getOrderedItems';

export const orderChallenges = (
  challenges: MemberChallenge[] = [],
  settings: any,
  chosenCategory: string,
  categories: Category[],
): MemberChallenge[] => {
  const orderFromSettings = getProgramsOrder({
    settings,
    chosenCategory,
    categories,
  });

  return orderFromSettings.length
    ? [
        ...orderFromSettings
          .map((orderedChall) => {
            const ch = challenges.find((_ch) => {
              return (
                getShortId(_ch?.challenge?.id) === getShortId(orderedChall?.id)
              );
            });

            return ch || null;
          })
          .filter((item) => !!item),
        ...challenges.filter((ch) => {
          const isInOrder = orderFromSettings.find(
            (i: { id: string }) =>
              getShortId(i.id) === getShortId(ch?.challenge?.id),
          );
          return !isInOrder;
        }),
      ]
    : challenges;
};
