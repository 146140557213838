import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  GetEligibleOrdersResponse,
  MemberOrdersByPlan,
  PlanStatus,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import { isMockedChallenge } from '../../../main/getMockedChallenges';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';
import { WarmupData } from '../../../../services/WarmupData';
import {
  getUserFromConfig,
  isLoggedInUser,
} from '../../../User/helpers/userContextHelpers';
import { resolveProgramId } from '../../Challenge';
import { defaultErrorOptions } from '../../../../utils/defaultErrorOptions';

const filterActivePlan = ({ planInfo }: any) =>
  planInfo.status === PlanStatus.ACTIVE;

const transformEligiblePlan = (eligPlan: MemberOrdersByPlan) => {
  if (eligPlan.memberOrders?.length) {
    eligPlan.memberOrders.forEach((order) => {
      if (order.validFrom && typeof order.validFrom === 'string') {
        /*
        We work with validFrom in context of date, not date+time.
        So some operation can work incorrectly with specific time, and because of it in this place time is replaced to first second of day.
        */
        order.validFrom = order.validFrom.replace(/T.*?Z/, 'T00:00:01.000Z');
      }

      return order;
    });
  }

  return eligPlan;
};

const WARMUPDATA_ELIGIBLE_ORDERS = 'WARMUPDATA_ELIGIBLE_ORDERS';

export async function getUserEligiblePlans(
  flowAPI: ControllerFlowAPI,
  challengeId?: string,
): Promise<MemberOrdersByPlan[]> {
  const warmupData = new WarmupData(flowAPI);
  challengeId = challengeId || (await resolveProgramId(flowAPI)).programId;
  const ssrData = warmupData.get(
    `${WARMUPDATA_ELIGIBLE_ORDERS}_${challengeId}`,
  );

  if (
    !challengeId ||
    !isLoggedInUser(flowAPI) ||
    isMockedChallenge(challengeId, flowAPI)
  ) {
    return [];
  }

  try {
    const orders: GetEligibleOrdersResponse =
      ssrData ||
      ((
        (
          await flowAPI.errorHandler.withErrorHandler(
            () =>
              flowAPI.httpClient.get(
                '/_serverless/challenges-web-serverless/get-eligible-orders',
                {
                  params: {
                    contactId: getUserFromConfig(flowAPI).id,
                    resourceId: challengeId,
                  },
                },
              ),
            defaultErrorOptions,
          )
        )?.data as any
      )?.orders as GetEligibleOrdersResponse);

    warmupData.set(`${WARMUPDATA_ELIGIBLE_ORDERS}_${challengeId}`, orders);

    return [
      ...orders.memberOrdersByPlan?.filter(filterActivePlan),
      ...orders.memberFutureOrdersByPlan?.filter(filterActivePlan),
    ]
      .filter((item) => !!item)
      .map(transformEligiblePlan);
  } catch (error) {
    const resolvedError = flowAPI.errorHandler.getResolvedError(error);
    handleError({
      resolvedError,
      context: 'getEligibleOrders',
      error,
    });

    return [];
  }
}
